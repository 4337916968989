import { gql } from '@apollo/client';

const CourseFragment = gql`
	fragment CourseFragment on Course {
		_id
		title
		description
		episodes {
			_id
			title
			summary
			video
			hash
			thumbnail
			language
			duration
			userProgress {
				_id
				progressPercentage
				watchedTime
				updatedAt
			}
		}
	}
`;

export default CourseFragment;
