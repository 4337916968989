import { useMutation } from '@apollo/client';
import TrackUserEpisodeProgressMutation from '@apps/www/src/www/queries/TrackUserEpisodeProgressMutation';
import SVLoadingIndicator from '@pkgs/shared-client/components/SVLoadingIndicator';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import Player from '@vimeo/player';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

type PlayerOptions = {
	height?: number;
	width?: number;
	quality?: string;
	autoplay?: number;
};

const defaultOptions: PlayerOptions = {
	quality: '4k',
	width: 1400,
	height: 787.5,
	autoplay: 0,
};

const SVVimeoPlayer = ({
	vimeoVideoId,
	hash,
	className = '',
	options = {},
	onEnded,
	startProgress,
	videoId,
	watchedTime,
}: {
	videoId: string;
	vimeoVideoId: string;
	className?: string;
	options?: PlayerOptions;
	hash: string;
	onEnded: () => void;
	startProgress?: number;
	watchedTime?: number;
}) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const playerRef = useRef<Player | null>(null);
	const watchedTimeRef = useRef<number>(watchedTime ?? 0);
	const videoDurationRef = useRef<number>(0);
	const videoCurrentTimeRef = useRef<number>(0);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isPlaying, setIsPlaying] = useState<boolean>(false);

	const [trackUserEpisodeProgress] = useMutation(TrackUserEpisodeProgressMutation);
	const videoURL = `https://player.vimeo.com/video/${vimeoVideoId}?h=${hash}&badge=0&player_id=0&app_id=58479&autoplay=${options.autoplay}`;

	const destroyPlayer = useEventCallback(async () => {
		if (!playerRef.current) {
			return;
		}

		try {
			await playerRef.current.destroy();
		} catch (error) {
			console.error('Error destroying the player:', error);
		}

		playerRef.current = null;
	});

	const trackProgress = (progressPercentage: number) => {
		if (isNaN(watchedTimeRef.current) || isNaN(progressPercentage)) {
			return;
		}

		const sanitizedProgressPercentage =
			progressPercentage >= (startProgress ?? 0)
				? progressPercentage === 99
					? 100
					: progressPercentage
				: startProgress ?? 0;

		// Set and forget, no need to await
		trackUserEpisodeProgress({
			variables: {
				input: {
					watchedTime: watchedTimeRef.current,
					progressPercentage: sanitizedProgressPercentage,
					episodeID: videoId,
				},
			},
		});
	};

	const handlePlayerLoaded = useEventCallback(async () => {
		if (!playerRef.current) {
			return;
		}

		const iframe = containerRef.current?.querySelector('iframe');
		if (iframe) {
			iframe.style.borderRadius = '16px';
			iframe.style.width = '100%';
			iframe.style.height = '100%';
		}

		const duration = await playerRef.current.getDuration();
		if (duration && (startProgress ?? 0) > 0) {
			const startTime = startProgress === 100 ? 0 : ((startProgress ?? 0) / 100) * duration;
			playerRef.current.setCurrentTime(startTime);
		}

		setIsLoading(false);
	});

	const trackAndDestroyPlayer = useEventCallback(async () => {
		if (!playerRef.current) {
			return;
		}

		const progressPercentage = Math.floor(
			(videoCurrentTimeRef.current / videoDurationRef.current) * 100,
		);
		if (progressPercentage < 99 && progressPercentage > 0) {
			trackProgress(progressPercentage);
		}

		await destroyPlayer();
	});

	const handlePlayerPlay = useEventCallback(() => {
		setIsPlaying(true);
	});

	const handlePlayerPause = useEventCallback(() => {
		setIsPlaying(false);
	});

	const handlePlayerEnded = useEventCallback(async () => {
		setIsPlaying(false);

		if (playerRef.current) {
			const [duration, currentTime] = await Promise.all([
				playerRef.current.getDuration(),
				playerRef.current.getCurrentTime(),
			]);

			trackProgress(Math.floor((currentTime / duration) * 100));
		}

		onEnded();
	});

	// As a handler so we don't add `isLoading` as a dependency to the effect
	const ensureIsLoading = useEventCallback(() => {
		if (!isLoading) {
			setIsLoading(true);
		}
	});

	useEffect(() => {
		if (!containerRef.current) {
			return;
		}

		(async () => {
			ensureIsLoading();

			await destroyPlayer();

			if (!containerRef.current) {
				return;
			}

			playerRef.current = new Player(containerRef.current, {
				url: videoURL,
				...defaultOptions,
				...options,
			});

			playerRef.current.on('loaded', handlePlayerLoaded);
			playerRef.current.on('play', handlePlayerPlay);
			playerRef.current.on('pause', handlePlayerPause);
			playerRef.current.on('ended', handlePlayerEnded);
		})();

		return () => {
			(async () => {
				await trackAndDestroyPlayer();
			})();
		};
	}, [
		containerRef.current,
		videoURL,
		...Object.values(options),
		handlePlayerLoaded,
		handlePlayerPlay,
		handlePlayerPause,
		handlePlayerEnded,
		destroyPlayer,
		trackAndDestroyPlayer,
		ensureIsLoading,
	]);

	useEffect(() => {
		const intervalId = setInterval(async () => {
			if (!isPlaying) {
				return;
			}

			const [duration, currentTime] = await Promise.all([
				playerRef.current.getDuration(),
				playerRef.current.getCurrentTime(),
			]);

			watchedTimeRef.current = Math.floor(watchedTimeRef.current + 1);
			videoCurrentTimeRef.current = currentTime;
			videoDurationRef.current = duration;
		}, 1000);

		return () => clearInterval(intervalId);
	}, [isPlaying]);

	return (
		<div className="relative">
			<div
				ref={containerRef}
				className={clsx(
					'flex-center aspect-video min-h-full min-w-full rounded-xl',
					className,
					isLoading ? 'invisible' : 'visible',
				)}
			/>
			{isLoading ? (
				<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
					<SVLoadingIndicator />
				</div>
			) : null}
		</div>
	);
};

export default SVVimeoPlayer;
