const CourseLanguageEnum = {
	PT_BR: 'pt-BR',
	EN_US: 'en-US',
} as const;

const labels = {
	PT_BR: 'Português',
	EN_US: 'English',
};

export const getCourseLanguageLabel = (language: string) => {
	const key = Object.keys(CourseLanguageEnum).find(
		(k) => CourseLanguageEnum[k as keyof typeof CourseLanguageEnum] === language,
	);
	return labels[key as keyof typeof labels] || language;
};

export default CourseLanguageEnum;
