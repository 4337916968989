import { useQuery } from '@apollo/client';
import { Episode } from '@apps/www/src/__generated__/graphql';
import SVRedirect from '@apps/www/src/www/containers/SVRedirect';
import SVWithRequiredLogin from '@apps/www/src/www/containers/SVWithRequiredLogin';
import useAuthUser from '@apps/www/src/www/hooks/useAuthUser';
import FindCourseQuery from '@apps/www/src/www/queries/FindCourseQuery';
import SVActions from '@pkgs/shared-client/components/SVActions';
import SVDropdown from '@pkgs/shared-client/components/SVDropdown';
import SVDropdownContent from '@pkgs/shared-client/components/SVDropdownContent';
import SVFlexSpacer from '@pkgs/shared-client/components/SVFlexSpacer';
import SVImage from '@pkgs/shared-client/components/SVImage';
import SVLandingPageFooter from '@pkgs/shared-client/components/SVLandingPageFooter';
import SVLoadingIndicator from '@pkgs/shared-client/components/SVLoadingIndicator';
import SVPageMargins, { WIDTHS } from '@pkgs/shared-client/components/SVPageMargins';
import config from '@pkgs/shared-client/config';
import { scrollToElement } from '@pkgs/shared-client/helpers/dom';
import SVCourseCheckSVG from '@pkgs/shared-client/img/icon-course-check-inlined.svg';
import CourseLanguageEnum, { getCourseLanguageLabel } from '@pkgs/shared/enums/CourseLanguage';
import clsx from 'clsx';
import { ChevronDown } from 'lucide-react';
import { useEffect, useState } from 'react';
import SVVimeoPlayer from './SVVimeoPlayer';
import SVWithBodyClass from '@pkgs/shared-client/containers/SVWithBodyClass';

const _EpisodeItem = ({
	episode,
	selectedEpisode,
	handleNextEpisode,
}: {
	episode: Episode;
	selectedEpisode: Episode | undefined;
	handleNextEpisode: (episodeID?: string) => void;
}) => (
	<div
		key={episode._id}
		className={clsx(
			'duration-slide -md:space-x-5 flex cursor-pointer flex-row space-x-20 overflow-hidden rounded-xl p-2 transition-all hover:scale-105',
			selectedEpisode?._id === episode._id ? 'bg-gray-900' : 'hover:bg-gray-900',
		)}
		onClick={() => {
			handleNextEpisode(episode._id);
			scrollToElement(document.getElementById('course-content-frame') as HTMLElement, -50);
		}}
	>
		<div className="-sm:w-full h-full w-56 overflow-hidden rounded-lg">
			<SVImage
				src={episode.thumbnail}
				alt={episode.title + ' - Thumbnail'}
				className="h-full w-full rounded-lg object-cover"
			/>
		</div>
		<div className="-sm:pr-0 -md:pr-5 flex w-full flex-row items-center justify-between space-x-2 pr-10">
			<div className="flex flex-col items-start justify-center space-y-1 text-left">
				<h2 className="type-subnav text-primary font-semibold">{episode.title}</h2>
				<p className="text-gray-500">{episode.summary}</p>
			</div>
			<SVFlexSpacer />
			{episode.userProgress?.progressPercentage === 100 ? (
				<_EpisodeCheckbox episode={episode} />
			) : null}
		</div>
	</div>
);

const _EpisodeCheckbox = ({ episode }: { episode: any }) => (
	<div
		className={clsx(
			'-sm:hidden flex items-center justify-center space-x-4 md:flex md:space-x-2',
			episode.userProgress?.progressPercentage === 100 ? 'text-primary' : 'text-gray-800',
		)}
	>
		<div className="-md:hidden md:block">Watched</div>
		<SVCourseCheckSVG className="h-7 w-7" />
	</div>
);

const getCurrentEpisode = (episodes: any[]) => {
	const lastEpisodeWatched = episodes?.findIndex((episode, index) => {
		const hasProgress = episode.userProgress !== null;
		const isFullyWatched = hasProgress && episode.userProgress?.progressPercentage === 100;

		if (isFullyWatched) {
			const nextEpisode = episodes[index + 1];
			return (
				nextEpisode?.userProgress === null ||
				nextEpisode?.userProgress?.progressPercentage === undefined ||
				nextEpisode?.userProgress?.progressPercentage < 100
			);
		}

		return false;
	});

	const selectedEpisodeIndex = lastEpisodeWatched !== undefined ? lastEpisodeWatched + 1 : 0;

	return episodes?.[selectedEpisodeIndex] ?? episodes?.[0];
};

const _SVCourseContent = () => {
	const authUser = useAuthUser(['name']);
	const [autoplay, setAutoplay] = useState<0 | 1>(0);

	const {
		data: course,
		loading,
		refetch,
	} = useQuery(FindCourseQuery, {
		variables: {
			courseID: config.workShop.courseID ?? '',
		},
		fetchPolicy: 'no-cache',
	});

	const [language, setLanguage] = useState<
		(typeof CourseLanguageEnum)[keyof typeof CourseLanguageEnum]
	>(CourseLanguageEnum.EN_US);

	const [episodes, setEpisodes] = useState<Episode[]>([]);
	const [selectedEpisode, setSelectedEpisode] = useState<Episode | undefined>(undefined);

	useEffect(() => {
		const determinedLanguage =
			(course?.findCourse?.episodes
				?.filter((ep) => ep.userProgress)
				?.sort(
					(a, b) =>
						new Date(b.userProgress?.updatedAt ?? '').getTime() -
						new Date(a.userProgress?.updatedAt ?? '').getTime(),
				)?.[0]?.language as (typeof CourseLanguageEnum)[keyof typeof CourseLanguageEnum]) ||
			(CourseLanguageEnum[
				navigator.language
					.replace('-', '_')
					.toUpperCase() as keyof typeof CourseLanguageEnum
			] ??
				CourseLanguageEnum.EN_US);

		setLanguage(determinedLanguage);
	}, [course]);

	useEffect(() => {
		if (!loading) {
			const filteredEpisodes =
				(course?.findCourse?.episodes.filter(
					(episode) => episode.language === language,
				) as Episode[]) ?? [];

			setEpisodes(filteredEpisodes);

			// Atualiza o episódio selecionado ao alterar a linguagem
			const currentEpisode = getCurrentEpisode(filteredEpisodes) || filteredEpisodes[0];
			setSelectedEpisode(currentEpisode);
		}
	}, [course, loading, language]);

	useEffect(() => {
		if (!loading && episodes && !selectedEpisode) {
			const currentEpisode = getCurrentEpisode(episodes) || episodes[0];
			handleNextEpisode(currentEpisode?._id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, episodes, selectedEpisode]);

	if (loading) return <SVLoadingIndicator />;

	if (!authUser || (!loading && !episodes && !selectedEpisode)) return <SVRedirect to="/team/" />;

	const handleNextEpisode = (episodeID?: string) => {
		setAutoplay(1);

		let nextEpisode: Episode | undefined = undefined;

		if (episodeID) {
			nextEpisode = episodes?.find(({ _id }) => _id === episodeID);
		} else {
			const currentIndex =
				episodes?.findIndex(({ _id }) => _id === selectedEpisode?._id) ?? 0;
			const isLastEpisode = currentIndex === (episodes?.length ?? 0) - 1;

			isLastEpisode && setAutoplay(0);
			nextEpisode = isLastEpisode ? episodes?.[0] : episodes?.[currentIndex + 1];
		}
		nextEpisode && setSelectedEpisode(nextEpisode);

		setTimeout(() => {
			refetch();
		}, 1000);
	};

	return (
		<div className="theme-dark mt-8 h-full w-full items-center justify-center">
			<SVPageMargins
				width={WIDTHS.COMPACT}
				className="flex flex-col items-center justify-center"
			>
				<div className="w-full max-w-[1400px] flex-col items-center justify-center">
					<div className="aspect-video h-full w-full" id="course-content-frame">
						{selectedEpisode ? (
							<SVVimeoPlayer
								key={selectedEpisode._id}
								vimeoVideoId={selectedEpisode.video}
								videoId={selectedEpisode._id}
								className="h-full w-full rounded-xl"
								hash={selectedEpisode.hash}
								onEnded={handleNextEpisode}
								options={{
									autoplay: autoplay,
								}}
								startProgress={selectedEpisode?.userProgress?.progressPercentage}
								watchedTime={selectedEpisode?.userProgress?.watchedTime}
							/>
						) : (
							<div className="flex h-full w-full items-center justify-center">
								<SVLoadingIndicator />
							</div>
						)}
					</div>
					<div className="-md:py-10 flex justify-between py-16 text-center text-4xl text-gray-500">
						<div>Episodes</div>
						<SVDropdown
							triggerType="click"
							renderTrigger={({ isOpen: _, ...props }) => (
								<SVActions.Item
									onClick={() => {}}
									{...props}
									className="flex items-center justify-center space-x-2"
								>
									<div className="text-base font-semibold text-gray-600">
										{getCourseLanguageLabel(language)}
									</div>
									<ChevronDown className="h-5 w-5 text-gray-600" />
								</SVActions.Item>
							)}
							renderContent={() => (
								<SVDropdownContent.Links>
									{Object.values(CourseLanguageEnum).map((language) => (
										<SVDropdownContent.Links.Item
											key={language}
											title={getCourseLanguageLabel(language)}
											onClick={() => setLanguage(language)}
										>
											{getCourseLanguageLabel(language)}
										</SVDropdownContent.Links.Item>
									))}
								</SVDropdownContent.Links>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-4">
						{episodes?.map((episode: Episode) => (
							<_EpisodeItem
								key={episode._id}
								episode={episode}
								selectedEpisode={selectedEpisode}
								handleNextEpisode={handleNextEpisode}
							/>
						))}
					</div>
				</div>
				<SVLandingPageFooter className="-md:mt-24 mt-40" />
			</SVPageMargins>
		</div>
	);
};

const SVCourseContent = SVWithBodyClass('theme-dark')(_SVCourseContent);

export default SVCourseContent;