import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type TrackUserEpisodeProgressMutationMutation,
	type TrackUserEpisodeProgressMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';

const TrackUserEpisodeProgressMutation: TypedDocumentNode<
	TrackUserEpisodeProgressMutationMutation,
	TrackUserEpisodeProgressMutationMutationVariables
> = gql`
	mutation TrackUserEpisodeProgressMutation($input: TrackUserEpisodeProgressInput!) {
		trackUserEpisodeProgress(input: $input) {
			_id
			progressPercentage
			watchedTime
		}
	}
`;

export default TrackUserEpisodeProgressMutation;
