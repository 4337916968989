import SVCourseContent from '@apps/www/src/www/components/SVCourseContent';
import SVRedirect from '@apps/www/src/www/containers/SVRedirect';
import SVWithRequiredLogin from '@apps/www/src/www/containers/SVWithRequiredLogin';
import useFeatureFlag from '@apps/www/src/www/hooks/useFeatureFlag';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';

const _SVCoursePage = () => {
	const isLoggedIn = useIsLoggedIn();
	const canAccessCourse = useFeatureFlag('course');

	if (!isLoggedIn || !canAccessCourse) {
		return <SVRedirect to="/" />;
	}

	return <SVCourseContent />;
};

const SVCoursePage = SVWithRequiredLogin(_SVCoursePage);

export default SVCoursePage;
