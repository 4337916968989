import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type FindCourseQueryQuery,
	type FindCourseQueryQueryVariables,
} from '@apps/www/src/__generated__/graphql';
import CourseFragment from './fragments/CourseFragment';

const FindCourseQuery: TypedDocumentNode<FindCourseQueryQuery, FindCourseQueryQueryVariables> = gql`
	${CourseFragment}

	query FindCourseQuery($courseID: ID!) {
		findCourse(courseID: $courseID) {
			...CourseFragment
		}
	}
`;

export default FindCourseQuery;
